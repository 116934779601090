
import template from './AlertConfirm.html';

class AlertConfirmVM
{
	constructor(dialog)
	{
		this.dialog = dialog;
		this.title = ko.observable(dialog.bindings.title || '');
		this.message = ko.observable(dialog.bindings.message || '');
		this.type_class = ko.observable(dialog.bindings.class || '');
		this.icon = ko.observable(dialog.bindings.icon || '');
		this.cancel_text = ko.observable(dialog.bindings.cancel_text || 'No');
		this.accept_text = ko.observable(dialog.bindings.accept_text || 'Yes');
		this.cancel_type = ko.observable(dialog.bindings.cancel_type || 'danger');
		this.accept_type = ko.observable(dialog.bindings.accept_type || 'success');
	}

	confirm()
	{
		this.dialog.close(true);
	}

	other()
	{
		this.dialog.close(false);
	}
}

class AlertConfirmDialog
{
	constructor(bindings, element)
	{
		this.bindings = bindings || {};
		this.viewModel = new AlertConfirmVM(this); // Name of the ViewModel defined above
		this.name = 'NewDialog'; // Unique dialog name
	}
}

export default {
	name: 'AlertConfirm',
	dialog_class: AlertConfirmDialog,
	provider: 'ps',
	template: template
}
