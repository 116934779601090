// COMPONENTS
import data_pagination from './data-pagination/index.js';
import fields_selector from './fields-selector/fields-selector.js';
import fields_filter from './fields-filter/fields-filter.js';
import picker from './picker/picker.js';
import select_with_input from './ko-select-with-input/ko-select-with-input.js';
import select_dropdown from './select-dropdown/select-dropdown.js';
import visible_columns from './visible-columns/visible-columns.js';
import tom_select from './tom-select/tom-select.js';
import ps_select from './ps-select/ps-select.js';
import ps_datetime_picker from './ps-datetime-picker/ps-datetime-picker.js';
import ps_upload_bar from './ps-upload-bar/ps-upload-bar.js';
import ko_tabs_dashboard from "./ko-tabs-dashboard/ko-tabs-dashboard.js";
import ko_page_title from "./ko-page-title/ko-page-title.js";
import ps_multiple_datepicker from "./ps-multiple-datepicker/ps-multiple-datepicker.js";
import ps_loader from './loader/ps-loader.js';

export default [
	data_pagination,
	fields_selector,
	fields_filter,
	picker,
	select_with_input,
	select_dropdown,
	visible_columns,
	tom_select,
	ps_select,
	ps_datetime_picker,
	ps_upload_bar,
	ko_tabs_dashboard,
	ko_page_title,
	ps_multiple_datepicker,
	ps_loader
];
