import template from './select-dropdown.html';

/**
* @class SelectDropdownComponentViewModel
* @constructor 
* @param {text} selected_item- selected item
* @param {text[]} selected_items- list of selected items
* @param {bool} [show_hidden_items=false] - shows active stock items (false) or all stock items (true)
* @param {bool} [multiple=false] - enables multiple selection
* @param {text} [title] - title of the dropdown
*/
// TODO add param to select field name to display if objects are passed
class SelectDropdownComponentViewModel
{
	constructor (params)
	{
		this.params = params;

		this.available_items = ko.observableArray();
		this.selected_item = ko_helper.safe_observable(params.selected_item || '');
		this.selected_items = ko_helper.safe_observableArray(params.selected_items || '');
		this.selected_display_value = ko.observable();
		this.title = ko.observable(params.title || 'Select item...');
		this.multiple = ko.observable(params.multiple || false);
		this.options_text = ko_helper.safe_observable(params.options_text || '');

		this.selected_item.subscribe((newValue) => {
			this.build_selected_items_obj();
		});

		this.selected_items.subscribe((newSelectedItems) => {
			this.build_selected_items_obj();
		});

		this.init();
	}

	async init ()
	{
		// TODO use ko.utils.unwrapObservable
		// doesn't work properly with observables
		this.available_items(this.params.available_items || []);
	}

	build_selected_items_obj () 
	{
		if (this.multiple())
		{
			let value;
			if(this.options_text())
				value = this.selected_items().map((s) => { return  s[this.options_text()]});
			else
				value = this.selected_items().map((s) => { return  s});
			
			this.selected_display_value(value);
		}
		else
			this.selected_display_value(this.selected_item());
	}

	item_click (row)
	{
		if (this.multiple())
			if (this.selected_items().includes(row))
				this.selected_items.splice(this.selected_items().indexOf(row), 1);
			else
				this.selected_items.push(row);
		else
			this.selected_item(row);
	}
	
	is_selected (item)
	{
		// Display selected items
		if (this.multiple())
			return this.selected_items().some(selected_item => this.options_text() == '' ? selected_item === item : selected_item[this.options_text()] === item[this.options_text()]);
		else
			return (this.options_text() == '' ? this.selected_item() == item : this.selected_item()[this.options_text()] == item[this.options_text()])
	}
}

export default {
	name: 'select-dropdown',
	viewModel: SelectDropdownComponentViewModel,
	module_type: 'ko',
	template: template
}