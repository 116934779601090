import template from './ps-loader.html';

class PSLoaderViewModel
{
	constructor (params)
	{
		this.params = params;
		this.icon = ko_helper.safe_observable(params.icon || 'fa-regular fa-spinner fa-spin');
		this.message = ko_helper.safe_observable(params.message || '');
		this.show = ko_helper.safe_observable(params.show || false);
	}
}

export default {
	name: 'ps-loader',
	viewModel: PSLoaderViewModel,
	module_type: 'ko',
	template: template
}
